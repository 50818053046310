/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img",
    h1: "h1",
    a: "a",
    div: "div",
    em: "em",
    h2: "h2",
    ul: "ul",
    li: "li",
    pre: "pre",
    code: "code",
    strong: "strong"
  }, _provideComponents(), props.components), {Warning} = _components;
  if (!Warning) _missingMdxReference("Warning", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://media.giphy.com/media/9xlzhm7XZFaja1E6QX/giphy.gif",
    alt: "A man alternating between the delete and enter keys"
  })), "\n", React.createElement(_components.p, null, "I’m done with AWS keys."), "\n", React.createElement(_components.p, null, "Keeping AWS keys around is like having a key to your house. You have to keep the keys safe, make sure you’ve got access to them any time you need them, and if somebody ever managed to steal a key or even a copy of that key, they could walk straight in whilst you’re out, and the next thing you know your sofa is gone, your freezer is blasting hot air, and your TV is running up thousands in bitcoin mining costs."), "\n", React.createElement(_components.p, null, "… or something like that."), "\n", React.createElement(_components.p, null, "Anyway, that’s why I moved all of my pipelines to OIDC."), "\n", React.createElement(_components.h1, {
    id: "what-is-oidc",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-oidc",
    "aria-label": "what is oidc permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is OIDC?"), "\n", React.createElement(_components.p, null, "OpenID Connect (OIDC) is an authentication protocol that allows users to authenticate with an identity provider (IDP) and obtain a token that can be used to access protected resources. OIDC is built on top of the OAuth 2.0 protocol and provides additional features such as user authentication and user information."), "\n", React.createElement(_components.p, null, "In the above analogy, OIDC is kind of like having a friend standing at the door of your house. That friend checks to make sure you’re ", React.createElement(_components.em, null, "really you"), " before they let you in by checking with somebody you’ve both agreed you can trust."), "\n", React.createElement(_components.p, null, "When you’re building a pipeline, a lot of the time you have to give your tooling some pretty uncomfortable access. At the very least, it’ll likely be able to build and push to an artifact repository such as ECR or S3, and may even be able to start processes in your cloud environment. You don’t want malicious actors having access to these sorts of roles."), "\n", React.createElement(_components.p, null, "In this post I’m going to run through how to set up an IDP using Terraform to work with GitHub Actions, and a sample GitHub Action workflow that authenticates with our AWS environment using OIDC."), "\n", React.createElement(_components.h1, {
    id: "how-to-use-oidc-for-aws-in-github-actions",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-use-oidc-for-aws-in-github-actions",
    "aria-label": "how to use oidc for aws in github actions permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to use OIDC for AWS in GitHub Actions"), "\n", React.createElement(_components.h2, {
    id: "assumptions-i-make-about-the-reader",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#assumptions-i-make-about-the-reader",
    "aria-label": "assumptions i make about the reader permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Assumptions I make about the reader"), "\n", React.createElement(_components.p, null, "Expanding out on any topic could take us all the way to the absolute foundations of concepts, and neither of us want me to write ", React.createElement(_components.em, null, "that"), " much, so I’m including some basic assumptions about you, the reader. If these assumptions are wrong then let me know, and maybe I’ll write about that next:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "You’re familiar with Terraform"), "\n", React.createElement(_components.li, null, "You’re familiar with GitHub Actions"), "\n"), "\n", React.createElement(_components.h2, {
    id: "setting-up-an-oidc-idp",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#setting-up-an-oidc-idp",
    "aria-label": "setting up an oidc idp permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Setting up an OIDC IDP"), "\n", React.createElement(_components.p, null, "The Terraform block for setting up an IDP is actually pretty simple:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-hcl"
  }, "resource \"aws_iam_openid_connect_provider\" \"github\" {\n  url             = \"https://token.actions.githubusercontent.com\"\n  client_id_list  = [\"sts.amazonaws.com\"]\n  thumbprint_list = [\"\"]\n}\n")), "\n", React.createElement(_components.p, null, "But before we can run it, we do need to fill out the thumbprint_list variable. These thumbprints are how AWS verifies that an OIDC token has actually been provided by GitHub (as they’re actually the thumbprint of a certificate that the provider will advertise)."), "\n", React.createElement(_components.p, null, "AWS provide a very handy guide for getting the thumbprint for a given url ", React.createElement(_components.a, {
    href: "https://docs.aws.amazon.com/IAM/latest/UserGuide/id_roles_providers_create_oidc_verify-thumbprint.html"
  }, "here"), " - you can either follow these instructions using the url in the above terraform block OR you can use a little docker image I created just for you!*"), "\n", React.createElement(Warning, null, "As we’re talking about security in our build tooling and supply chains, I’m going to take a moment to remind folks to ", React.createElement(_components.em, null, "always double check"), " before running / trusting a tool, especially if it’s in the security space! You can find the repository for the Docker image I’m about to show you ", React.createElement(_components.a, {
    href: "https://github.com/JoshuaSimpson/oidc-checker"
  }, "here")), "\n", React.createElement(_components.p, null, "If you have Docker installed, you can simply run:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "docker run snufflufagus/oidc <URL>\n\n# in this instance, it would be\n\ndocker run snufflufagus/oidc https://token.actions.githubusercontent.com\n")), "\n", React.createElement(_components.p, null, "Once you have your thumbprint, throw it in your Terraform block (this thumbprint is correct at the time of publishing - 17th March, 2023)."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-hcl"
  }, "resource \"aws_iam_openid_connect_provider\" \"github\" {\n  url             = \"https://token.actions.githubusercontent.com\"\n  client_id_list  = [\"sts.amazonaws.com\"]\n  thumbprint_list = [\"6938fd4d98bab03faadb97b34396831e3780aea1\"]\n}\n")), "\n", React.createElement(_components.p, null, "Finally:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "terraform apply\n")), "\n", React.createElement(_components.p, null, "If you want to check, you'll be able to find your new IDP in the IAM service in AWS, under Identity Providers."), "\n", React.createElement(_components.h2, {
    id: "creating-an-iam-role-to-use-our-oidc-token-with",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#creating-an-iam-role-to-use-our-oidc-token-with",
    "aria-label": "creating an iam role to use our oidc token with permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Creating an IAM role to use our OIDC token with"), "\n", React.createElement(_components.p, null, "Now that we’ve got our IDP set up, we need an IAM role to authenticate as. For the purposes of this tutorial, we’re going to push a file into an existing S3 bucket, but ultimately anything that you want to do can be achieved by swapping out the permissions in the IAM policy document we create."), "\n", React.createElement(_components.p, null, "First, we need a couple of data blocks:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-hcl"
  }, "data \"aws_iam_policy_document\" \"assume_role_policy\" {\n  statement {\n    effect = \"Allow\"\n    actions = [\"sts:AssumeRoleWithWebIdentity\"]\n\n    principals {\n      type        = \"Federated\"\n      identifiers = [aws_iam_openid_connect_provider.github.arn]\n    }\n\n    condition {\n      test     = \"StringLike\"\n      variable = \"token.actions.githubusercontent.com:sub\"\n      values   = [\"repo:<YOUR_GITHUB_USERNAME_OR_ORG_NAME_HERE>/<YOUR_REPO_NAME_HERE>:*\"]\n    }\n  }\n}\n")), "\n", React.createElement(_components.p, null, "So what does this do?"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "actions"), " block tells AWS that some actors may assume the role. We don't want to make it so that ", React.createElement(_components.em, null, "anybody"), " can do this, so we narrow it down using our ", React.createElement(_components.code, null, "principals"), " and ", React.createElement(_components.code, null, "condition"), " blocks:"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "principals"), " - this block specifies that the thing trying to assume the role ", React.createElement(_components.em, null, "has"), " to be via our IDP, which is a good start - but if we just left it at that, then ", React.createElement(_components.em, null, "anybody"), " could use GitHub Actions to authenticate with this role. That's not good - so we use condition blocks to narrow it down."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "condition"), " - this particular block uses the StringLike condition (read more about conditions ", React.createElement(_components.a, {
    href: "https://docs.aws.amazon.com/IAM/latest/UserGuide/reference_policies_elements_condition.html"
  }, "here"), ") against the claims that our OIDC token will make. You find a full list of things you could test against in a GitHub OIDC token ", React.createElement(_components.a, {
    href: "https://docs.github.com/en/actions/deployment/security-hardening-your-deployments/about-security-hardening-with-openid-connect"
  }, "here"), ", and change out ", React.createElement(_components.code, null, "sub"), " for whatever value you'd like to test against."), "\n", React.createElement(_components.p, null, "In this block, we're specifying that only a specific GitHub repo can use this role, but the Action could come from any branch or Git ref (as dictated by the wildcard *). You could swap out the wildcard to specify a branch (which is useful if you do branch based deployment), or other Git compatible reference."), "\n", React.createElement(_components.p, null, "Now, by itself, this will do nothing - we need to attach it to a role!"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-hcl"
  }, "resource \"aws_iam_role\" \"deploy_role\" {\n  name = \"deploy-role\"\n  assume_role_policy = data.aws_iam_policy_document.assume_role_policy.json\n}\n\nresource \"aws_iam_role_policy_attachment\" \"attach_deploy_policy\" {\n  role       = aws_iam_role.deploy_role.name\n  policy_arn = aws_iam_policy.ecs_deploy_policy.arn\n}\n\nresource \"aws_iam_policy\" \"ecs_deploy_policy\" {\n  name   = \"deploy-policy\"\n  policy = data.aws_iam_policy_document.deploy_document.json\n}\n\ndata \"aws_iam_policy_document\" \"deploy_document\" {\n  statement {\n    effect = \"Allow\"\n    actions = [\n      \"s3:PutObject\"\n    ]\n    resources = [\n      \"${aws_s3_bucket.test.arn}\"\n    ]\n  }\n}\n\nresource \"aws_s3_bucket\" \"test\" {\n  bucket = \"test.man-yells-at.cloud\"\n  acl    = \"private\"\n\n  versioning {\n    enabled = true\n  }\n}\n")), "\n", React.createElement(_components.p, null, "As this is just a demonstration, I've created an S3 bucket and given our role permissions to put objects in that bucket."), "\n", React.createElement(_components.p, null, "Now we've created a role, we've given it some permissions, and we've specified what can assume that role. Let's give it a whirl in a workflow!"), "\n", React.createElement(_components.h2, {
    id: "testing-our-new-role",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#testing-our-new-role",
    "aria-label": "testing our new role permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Testing our new role"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "name: Test our new OIDC role!\n\non:\n  push:\n    branches: [main]\n  workflow_dispatch:\n\npermissions:\n    id-token: write   # This is required for requesting the JWT\n    contents: read    # This is required for actions/checkout\n\njobs:\n  oidc-test:\n    runs-on: ubuntu-latest\n    steps:\n      - name: Configure aws credentials\n        uses: aws-actions/configure-aws-credentials@v1\n        with:\n          role-to-assume: ${{ secrets.AWS_ROLE_ARN }}\n          aws-region: eu-west-1\n    \n      - name: Sync the repo with S3\n        id: sync-s3\n        run:\n          echo \"testdata\" > my-test-file.txt\n          aws s3 cp my-test-file.txt s3://${{secrets.S3_BUCKET_NAME}}\n\n")), "\n", React.createElement(_components.p, null, "We give the GitHub Action block the ARN of the role that we just created, and the region that we want to assume the role in, and then that session will persist through our workflow, which means in the next step we can create a file and copy it into S3!"), "\n", React.createElement(_components.p, null, "It's important to note the permissions at the top of our flow - this tells Actions that this workflow is allowed to generate a token."), "\n", React.createElement(_components.p, null, "Finally, we're going to push that into our GitHub repository and see what happens. If you've been following along with my steps, you'll notice that I've used a couple of secrets for my AWS_ROLE_ARN and S3_BUCKET_ARN - you'll want to set those secrets up in GitHub before running this workflow!"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 1200px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/fa584de5d90bc3a089e72d97d2bc02d3/2643c/success.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 22.333333333333336%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAk0lEQVQY042NyRKCMBBEgxoggSSELGwRLaC0/P8PbGUOHpXDqzn09GuWtgduywvTvBFDWmHaHrXxqE04hLYddX13BRvmFS5MsH6kwMUEqRxOXP4kuwjwUkHbHpUOKOsWhTRgaXmSpA0TrVXaQ9mIXBiwc0nF/R6BCw3WjXc0boAykWRSOxIWVYOMy6/0H/tf/hG+AZ+lW/5pjzweAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"A screenshot of GitHub Actions showing a successfully completed workflow\"\n        title=\"A screenshot of GitHub Actions showing a successfully completed workflow\"\n        src=\"/static/fa584de5d90bc3a089e72d97d2bc02d3/c1b63/success.png\"\n        srcset=\"/static/fa584de5d90bc3a089e72d97d2bc02d3/5a46d/success.png 300w,\n/static/fa584de5d90bc3a089e72d97d2bc02d3/0a47e/success.png 600w,\n/static/fa584de5d90bc3a089e72d97d2bc02d3/c1b63/success.png 1200w,\n/static/fa584de5d90bc3a089e72d97d2bc02d3/2643c/success.png 1747w\"\n        sizes=\"(max-width: 1200px) 100vw, 1200px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Hopefully you've reached the end of this with a GitHub Action that's just done something in AWS without you having to give GitHub a set of credentials!"), "\n", React.createElement(_components.p, null, "If you're looking to expand upon this, it's worth taking a look at what condition keys are available, and what claims you can use from GitHub's OIDC token for more secure / complex combinations and workflows."), "\n", React.createElement(_components.p, null, "It's also worth remembering that this isn't the pinnacle of security, it's just better ", React.createElement(_components.em, null, "in most cases"), ". By implementing OIDC in my pipelines, I'm giving some degree of control over my security mechanisms to another entity, so I need to make sure I trust that entity before implementing this, I need to review that trust on a frequent basis, and I should absolutely combine it with other tools and mechanisms. There's a whole world of ", React.createElement(_components.a, {
    href: "https://aws.amazon.com/products/security/?nc=sn&loc=2"
  }, "security tools within AWS"), " and that's before even taking a look at the sea of vendors who all offer their own solutions."), "\n", React.createElement(_components.p, null, "I'll take my tin foil hat off now."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
