import GATSBY_COMPILED_MDX from "/Users/joshsimpson/Documents/personal/man-yells-at-cloud/gatsby/blog/deleting-aws-keys/deleting-aws-keys.mdx";
import * as React from "react";
import {MDXProvider} from "@mdx-js/react";
import Layout from "../../components/Layout";
import HeroImage from "../../components/Blog/HeroImage";
import components from "../../components/MarkdownComponents";
var BlogPost = function BlogPost(_ref) {
  var data = _ref.data, children = _ref.children;
  var siteTitle = data.site.siteMetadata.title;
  var pageTitle = data.mdx.frontmatter.title;
  var fullTitle = pageTitle + " | " + siteTitle;
  return React.createElement(Layout, {
    pageTitle: fullTitle
  }, React.createElement("article", {
    className: "blog-post px-3 py-5 p-md-5"
  }, React.createElement("div", {
    className: "container single-col-max-width"
  }, React.createElement("header", {
    className: "blog-post-header"
  }, React.createElement("h2", {
    className: "title mb-2"
  }, data.mdx.frontmatter.title), React.createElement("div", {
    className: "meta mb-3"
  }, React.createElement("span", {
    className: "date"
  }, data.mdx.frontmatter.date))), React.createElement("div", {
    className: "blog-post-body"
  }, data.mdx.frontmatter.hero_in_post === true && React.createElement(HeroImage, {
    image: data.mdx.frontmatter.hero_image,
    altText: data.mdx.frontmatter.hero_image_alt,
    creditText: data.mdx.frontmatter.hero_image_credit_text,
    creditLink: data.mdx.frontmatter.hero_image_credit_link
  }), React.createElement(MDXProvider, {
    components: components
  }, children)))));
};
var query = "2053309012";
BlogPost
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPost, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
